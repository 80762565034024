import Swiper from 'swiper/bundle';

export default {
	init() {
		const pressCarousels = document.getElementsByClassName('press-carousel__carousel');
		const pressCarouselsArr = Array.from(pressCarousels);

		pressCarouselsArr.forEach((carousel) => {
			const carouselId = carousel.id;

			const pressCarousel = new Swiper(carousel, {
				slidesPerView: 1,
				spaceBetween: 24,
				navigation: {
					nextEl: '#' + carouselId + '-nav .press-carousel__nav__next',
					prevEl: '#' + carouselId + '-nav .press-carousel__nav__prev'
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 24,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 24,
					},
				}
			});
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};