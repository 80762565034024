import Swiper from 'swiper/bundle';

export default {
	init() {
		const videoCarousel = new Swiper('.swiper.home-video__carousel', {
			slidesPerView: 1,
			spaceBetween: 40,
			initialSlide: 1,
			centeredSlides: true,
			loop: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpoints: {
				768: {
					slidesPerView: 1.4,
					spaceBetween: 50,
				},
				1024: {
					slidesPerView: 1.4,
					spaceBetween: 110,
				},
			}
		});

		const hideVideoOverlay = () => {

			const videoButton = document.querySelectorAll('.home-video__slide__button');

			videoButton.forEach((button) => {
				button.addEventListener('click', (e) => {
					e.target.parentElement.classList.add('hidden');
				})
			});
		}

		hideVideoOverlay();
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};