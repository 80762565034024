export default {
  init() {
    // JavaScript to be fired on all pages

    const pageBody = document.body
    const header = document.getElementById('header')

    let scrollPos

    document.addEventListener('scroll', e => {
      if (!pageBody.classList.contains('fixed')) {
        scrollPos = window.scrollY
      }

      let ticking = false
      if (!ticking) {
        setTimeout(function () {
          if (scrollPos > 0) {
            header.classList.add('scrolled')
          } else {
            header.classList.remove('scrolled')
          }
          ticking = false
        }, 16)
        ticking = true
      }
    })

    const nav = () => {
      const mainNav = document.querySelector('.header__nav'),
        mobileNavToggle = document.querySelector('.nav-toggle'),
        subnavToggle = document.querySelectorAll('.subnav-toggle'),
        subnav = document.querySelectorAll('.nav-subnav'),
        subnavLinks = document.querySelectorAll('.subnav-item a')

      let intViewportWidth = window.innerWidth

      // reset nav for desktop/mobile
      const resetNav = () => {
        subnavToggle.forEach(toggle => {
          toggle.setAttribute('aria-pressed', 'false')
        })
        subnav.forEach(nav => {
          nav.setAttribute('aria-hidden', 'true')
          nav.style.height = 0
        })
        subnavLinks.forEach(link => {
          // prevent tabbing to hidden links
          link.setAttribute('tabindex', '-1')
        })
        pageBody.classList.remove('fixed')
        pageBody.removeAttribute('top')
        window.scrollTo(0, scrollPos)
      }

      // initial nav setup
      const setNav = () => {
        intViewportWidth = window.innerWidth
        if (intViewportWidth < 1024) {
          mobileNavToggle.setAttribute('aria-pressed', 'false')
          mainNav.setAttribute('aria-hidden', 'true')
        } else {
          mobileNavToggle.setAttribute('aria-pressed', 'false')
          mainNav.removeAttribute('aria-hidden')
        }
        resetNav()
      }

      // mobile nav toggle
      const toggleMainNav = () => {
        mobileNavToggle.addEventListener('click', e => {
          if (e.target.getAttribute('aria-pressed') === 'true') {
            // keep doc scroll position
            pageBody.classList.remove('fixed')
            pageBody.removeAttribute('top')
            window.scrollTo(0, scrollPos)
            // toggle menu
            e.target.setAttribute('aria-pressed', 'false')
            mainNav.setAttribute('aria-hidden', 'true')
          } else {
            // toggle menu
            e.target.setAttribute('aria-pressed', 'true')
            mainNav.setAttribute('aria-hidden', 'false')
            // keep page from scrolling/set current scroll pos
            setTimeout(() => {
              pageBody.classList.add('fixed')
              pageBody.setAttribute('top', scrollPos + 'px')
            }, 301)
          }
        })
      }

      // subnav toggle for desktop/mobile
      const toggleSubnav = () => {
        const toggler = target => {
          if (target.getAttribute('aria-pressed') === 'true') {
            resetNav()
          } else {
            resetNav()
            const itemSubnav = target.nextElementSibling,
              itemSubnavItem = itemSubnav.querySelectorAll('.subnav-item')
            let itemSubnavHeight = 0
            itemSubnavItem.forEach(link => {
              // get total subnav height
              itemSubnavHeight = itemSubnavHeight + link.offsetHeight
              // allow tabbing to visible links
              link.querySelector('a').toggleAttribute('tabindex')
            })
            target.setAttribute('aria-pressed', 'true')
            itemSubnav.setAttribute('aria-hidden', 'false')
            itemSubnav.style.height = itemSubnavHeight + 'px'

            // Close subnav menu when clicking outside
            document.addEventListener('click', closeSubnavOnClickOutside)
          }
        }

        const closeSubnavOnClickOutside = event => {
          const subnavMenus = document.querySelectorAll('.subnav-menu')
          const isClickInsideSubnav = Array.from(subnavMenus).some(subnavMenu =>
            subnavMenu.contains(event.target)
          )
          if (!isClickInsideSubnav) {
            resetNav()
            document.removeEventListener('click', closeSubnavOnClickOutside)
          }
          event.stopPropagation() // Prevent event from propagating further
        }

        subnavToggle.forEach(toggle => {
          toggle.addEventListener('click', e => {
            e.preventDefault()
            toggler(e.target)
            e.stopPropagation() // Prevent event from propagating further
          })
        })
      }


      window.onload = setNav
      window.onresize = setNav
      toggleMainNav()
      toggleSubnav()
    }

    nav()

    // intersection observer
    const setInView = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view')
        }
      })
    }

    const itemOptions = {
      threshold: 0.5
    }

    const itemObserver = new IntersectionObserver(setInView, itemOptions)

    const items = document.querySelectorAll('.animate')
    items.forEach(item => {
      itemObserver.observe(item)
    })

  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
}
