export default {
	init() {
		const videoButton = document.getElementById('magazine-video-btn');
		const hideVideoOverlay = () => {
			videoButton.addEventListener('click', (e) => {
				e.target.parentElement.classList.add('hidden');
			})
		}

		if (videoButton) {
			hideVideoOverlay();
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
